import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { UserSessionTimeoutService } from 'src/app/_navigator/user-session-timeout/service/user-session-timeout.service';

@Component({
  selector: 'user-confirmation-modal',
  templateUrl: './user-confirmation-modal.component.html',
  styleUrls: ['./user-confirmation-modal.component.scss']
})
export class UserConfirmationModalComponent implements OnInit, OnDestroy {

  private promptTimeInMins!: number;
  public displayDialog: boolean = true;
  private minutes: number = 0;
  private seconds: number = 0;
  private intervalId: any;

  constructor(private userTimeoutSvc: UserSessionTimeoutService, private auth: AuthService) { }

  ngOnInit(): void {
    this.startCountdown(this.promptTimeInMins);
  }

  private startCountdown(minutes: number): void {
    this.minutes = minutes;
    this.seconds = 0;
    this.intervalId = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          clearInterval(this.intervalId);
          this.userTimeoutSvc.forceSignOut();
        } else {
          this.minutes--;
          this.seconds = 59;
        }
      } else {
        this.seconds--;
      }
    }, 1000);
  }

  private closeDialog(){
    this.displayDialog = false;
  }

  private signOutUser() {
    this.auth.logout({
      logoutParams: {
          returnTo: window.location.origin,
      },
  });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }


}

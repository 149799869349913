import { Injectable, EventEmitter } from '@angular/core';
import { PrimeManager } from '../../modal/pmodal.manager';
import { UserConfirmationModalComponent } from '../../../home/user-confirmation-modal/user-confirmation-modal.component';


@Injectable()
export class UserSessionTimeoutService {

  private idleTimeout: number = 1200000; //20 mins
  private promptTime: number = 300000; // 5 mins
  private lastActivityTime: number;
  private idle: boolean = false;
  private warningDialogOpen: boolean = false;
  public userSessionPrompt: EventEmitter<number>;
  public userResponse: EventEmitter<string>;

  constructor() 
  { 
    this.idle = false;
    this.lastActivityTime = Date.now();
    this.setupEventListeners();
    this.checkIdleState();
    this.userSessionPrompt = new EventEmitter<number>();
    this.userResponse = new EventEmitter<string>();
  }

  private idleCallback() {
    if(!this.warningDialogOpen) {
      this.warningDialogOpen = true;
      this.userSessionPrompt.emit(this.promptTime);      
    } else return;
  }

  private setupEventListeners() {
    const events = ['mousemove', 'keydown', 'click', 'scroll'];
    events.forEach(event => {
      window.addEventListener(event, this.resetIdleTimer.bind(this));
    });
  }

  public resetIdleTimer() {
    this.lastActivityTime = Date.now();
    this.idle = false;
  }

  private checkIdleState() {
    setInterval(() => {
      if(!this.warningDialogOpen) {
        if (Date.now() - this.lastActivityTime > (this.idleTimeout - this.promptTime)) {
          if (!this.idle) {
            this.idle = true;
            this.idleCallback();
          }
        }
      } 
    }, 1000);
  }

  public forceSignOut() {
    this.resetIdleTimer();
    this.userResponse.emit('SESSION_TIMEOUT');
  }

  public staySignedIn() {
    this.warningDialogOpen = false;
    this.resetIdleTimer();
    this.userResponse.emit('STAY_SIGNED_IN');
  }

}

import * as moment from 'moment';

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Util } from '@concurrency/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import * as Highcharts from 'highcharts';
import { Dialog } from 'primeng/dialog';
import { Ccr, CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { UserResponse } from 'src/app/_api/responses/user.response';
import { TableData } from 'src/app/_navigator/data/model/table-data.model';
import { TrendsOverTimePDFExportService } from 'src/app/_navigator/data/service/trends-over-time-pdf-export.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { TrendsOverTimeIndustry } from 'src/app/benchmarking/data/trends-over-time-industry';
import { TrendsOverTimeMetric } from 'src/app/benchmarking/data/trends-over-time-metric';
import { Regionrisk } from 'src/app/benchmarking/data/trends-over-time-table-data';
import { TrendsOverTimecountryriskExcelExportService } from '../../exports/excel/trends-over-time-country-risk-excel-export.service';
import { TearSheetService } from '../data/tear-sheet.service';
import { TrendsOverTimeService } from '../data/trends-over-time.service';
import { Chart } from 'angular-highcharts';
import { Select } from '@ngxs/store';
import { EstimateSummaryState } from 'src/app/_navigator/estimate-summary/estimate-summary-state';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { Observable } from 'rxjs';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'trends-over-time-country-risk-premia',
    templateUrl: './trends-over-time-country-risk-premia.component.html',
    styleUrls: ['./trends-over-time-country-risk-premia.component.scss']
})
export class TrendsOverTimeCountryRiskPremiaComponent implements OnInit, AfterViewInit {

    @ViewChild('trendsovertimeriskpdf') public trendsovertimeriskpdf!: PDFExportComponent;
    @ViewChild('trendsovertimemodal') public trendsovertimemodal!: ElementRef;

    @Input() public trendsOverTimeMetric!: TrendsOverTimeMetric;
    @Input() public trendsOverTimeData!: CountryRiskPremia[];
    @Input() public creditrisk!: Ccr[][];
    @Input() public industryData: TrendsOverTimeIndustry[] = [];
    @Input() public trendsOverTimeIndustries!: TableData[];
    @Input() public countrycredit!: CountryCreditRating[];
    @Input() public investorcountryid!: number;
    @Input() public investeecountryid!: number;
    @Input() public Dataasof!: string;
    @Input() public userdata!: UserResponse;
    public metricTitle = '';
    private CountryFilterStatus = true;
    private MSCIFilterStatus = true;
    private EuromoneyFilterStatus = true;
    private CreditRatingFilterStatus = true;
    public moment = moment;
    public Util = Util;
    public display = true;
    public dialogHeader = 'Trends Over Time';
    public credirating!: CountryCreditRating[];
    public displayGraph = true;
    public displayContent = false;
    public lineChartOptionsArrayccr: Highcharts.Options[] = [];
    public lineChartOptionsArrayvoltality: Highcharts.Options[] = [];
    public lineChartOptionsArrayyeildspread: Highcharts.Options[] = [];

    //use 'ModelsFilterStatus' variable as per 'chartdata' variable's data order
    public chartdata = ['Country Credit Rating', 'Country Yield Spread', 'Relative Volatility'];
    private ModelsFilterStatus:boolean[] = [true, true, true];

    private IsChartDataAvailable: { [key: string]: boolean } = { "CCR_MODEL": false, "CYS_MODEL": false, "RV_MODEL": false }

    public excelFileName = '';
    public ex1!: any;
    public ex2!: string;
    public ex3!: string;
    public ex4!: string;
    public creditrating = true;
    public yeild = true;
    public volatility = true;

    public countryriskarray: any[] = [];
    public CcrAvgarrayeur: any[] = [];
    public CcrAvgarraymsci: any[] = [];
    public CcrAvgarrayspc: any[] = [];
    public dateArray: string[] = [];
    public YieldSpreadeur: any[] = [];
    public YieldSpreadmsci: any[] = [];
    public YieldSpreadspc: any[] = [];
    public VolatilityAvgeur: any[] = [];
    public VolatilityAvgmsci: any[] = [];
    public VolatilityAvgspc: any[] = [];
    public YieldSpread: any[] = [];
    public volatality: any[] = [];
    public Creditratingline = true;
    public pdficonhide = true;
    public modifiedDate = new Date().toLocaleDateString();
    public euroregionline = true;
    public subscription!: string;
    public msciline = true;

    public sprline = true;

    public highFinancialRiskLatestFilter = {
        display: false
    };

    public tableData = {} as Regionrisk;

    public tableData1 = {} as Regionrisk;
    public isFullScreenDisplay: boolean = false;
    public fullSizeChart!: Chart;

    @ViewChild(Dialog) public dialog!: Dialog;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    public estimate: EstimateSummary | undefined;

    constructor(
        protected dataStore: DataStore,
        public tearSheetService: TearSheetService,
        public trendsOverTimeService: TrendsOverTimeService,
        private trendsOverTimeExcelExportService: TrendsOverTimecountryriskExcelExportService,
        private trendsOverTimePDFExportService: TrendsOverTimePDFExportService
    ) { }

    private setGraphData(): void {
        this.ex1 = this.trendsOverTimeIndustries[0];
        this.ex1 = this.ex1[0];
        this.lineChartOptionsArrayccr = [];
        this.lineChartOptionsArrayvoltality = [];
        this.lineChartOptionsArrayyeildspread = [];
        this.setLineChartOptionsysm(this.trendsOverTimeData, this.countrycredit);
        this.setLineChartOptionsccr(this.trendsOverTimeData, this.countrycredit);
        this.setLineChartOptionsrvm(this.trendsOverTimeData, this.countrycredit);
    }

    private setTableData(): void {

        this.ex1 = this.trendsOverTimeIndustries[0];
        this.ex1 = this.ex1[0];
        this.trendsOverTimeData.forEach((data) => {
            if (data.Type === 'EuromoneyRegion') {
                this.ex2 = data.RegionName;
            }
            if (data.Type === 'MsciClassification') {
                this.ex3 = data.RegionName;
            }
            if (data.Type === 'SpCreditRating') {
                this.ex4 = data.RegionName;
            }
        });
        const trendsOverTimeData = [this.ex1, this.ex2, this.ex3, this.ex4];
        this.tableData = {
            trendsOverTimeData
        };
    }

    private setLineChartOptionsccr(trendOverTime: CountryRiskPremia[], countrycredit: CountryCreditRating[]): void {
        // Any type is used since highchart's data property in a series accepts many data types
        let countryriskarray: any[] = [];
        let CcrAvgarrayeur: any[] = [];
        let CcrAvgarraymsci: any[] = [];
        let CcrAvgarrayspc: any[] = [];

        let dateArray: string[] = [];

        let [IsCountryRiskAvailabel, IsCcrAvgEurAvailabel, IsCcrAvgMsciAvailabel, IsCcrAvgSandPAvailabel] = [false, false, false, false];

        countrycredit = this.removeDuplicates(countrycredit);
        countrycredit.forEach((request) => {
            if (this.Creditratingline !== false && (request.CountryRiskPremia !== null || request.CountryRiskPremia !== undefined)) {
                countryriskarray = [...countryriskarray,
                {
                    y: request.CountryRiskPremia * 100
                }
                ];
            }
        });

        trendOverTime.forEach((data) => {
            if (data.DataAsof !== undefined) {
                const date = new Date(data.DataAsof);
                if (data.Type === 'EuromoneyRegion') {
                    dateArray = [...dateArray, moment(date).format('MM/DD/YYYY')];
                }
            }
            if (data.CcrAvg !== null && data.Type === 'EuromoneyRegion' && this.euroregionline !== false && data.CcrMedian !== null) {
                this.ex2 = data.RegionName;
                CcrAvgarrayeur = [...CcrAvgarrayeur,
                {
                    y: data.CcrMedian
                }
                ];
            }
            if (data.CcrAvg !== null && data.Type === 'MsciClassification' && this.msciline !== false && data.CcrMedian !== null) {
                this.ex3 = data.RegionName;
                CcrAvgarraymsci = [...CcrAvgarraymsci,
                {
                    y: data.CcrMedian
                }
                ];
            }

            if (data.CcrAvg !== null && data.Type === 'SpCreditRating' && this.sprline !== false && data.CcrMedian !== null) {
                this.ex4 = data.RegionName;
                CcrAvgarrayspc = [...CcrAvgarrayspc,
                {
                    y: data.CcrMedian
                }
                ];
            }

        });

        this.countryriskarray = countryriskarray;
        this.CcrAvgarrayeur = CcrAvgarrayeur;
        this.CcrAvgarraymsci = CcrAvgarraymsci;
        this.CcrAvgarrayspc = CcrAvgarrayspc;
        this.dateArray = dateArray;

        IsCountryRiskAvailabel = countryriskarray.length > 0;
        IsCcrAvgEurAvailabel = CcrAvgarrayeur.length > 0;
        IsCcrAvgMsciAvailabel = CcrAvgarraymsci.length > 0;
        IsCcrAvgSandPAvailabel = CcrAvgarrayspc.length > 0;

        this.IsChartDataAvailable["CCR_MODEL"] = IsCountryRiskAvailabel || IsCcrAvgEurAvailabel || IsCcrAvgMsciAvailabel || IsCcrAvgSandPAvailabel;
        this.ModelsFilterStatus[0] = this.IsChartDataAvailable["CCR_MODEL"];

        let ChartLineNameAndColours = this.GetChartNameAndColours(this.ex1, this.ex2, this.ex3, this.ex4);
        const lineChartOptions: Highcharts.Options = {
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'nunito-sans'
                },
                className: 'trends-over-time'
            },
            exporting: {
                enabled: true
            },
            title: {
                text: 'Country Credit Rating Model (%)',
                style: {
                    color: 'rgb(77, 77, 79)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem'
                }
            },
            subtitle: {
                text: 'Country Risk Indications from a ' + this.estimate?.InvestorCountryName + ' Perspective',
                style: {
                    color: 'rgb(20, 72, 127)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem',
                    fontWeight: 'bold'
                }
            },
            tooltip: {
                backgroundColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 60 },
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                },
                borderWidth: 1,
                split: true,
                valueDecimals: 2
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    type: 'line',
                    name: ChartLineNameAndColours.Country.CountryChartName,
                    color: ChartLineNameAndColours.Country.Countrycolour,
                    connectNulls: true,
                    data: [...countryriskarray],
                    showInLegend: IsCountryRiskAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.Euromoney.Euromoneychartname,
                    color: ChartLineNameAndColours.Euromoney.Euromoneycolour,
                    connectNulls: true,
                    data: [...CcrAvgarrayeur],
                    showInLegend: IsCcrAvgEurAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.MSCI.MSCIchartname,
                    color: ChartLineNameAndColours.MSCI.MSCIcolour,
                    connectNulls: true,
                    data: [...CcrAvgarraymsci],
                    showInLegend: IsCcrAvgMsciAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.CreditRating.Creditratingchartname,
                    color: ChartLineNameAndColours.CreditRating.Creditratingcolour,
                    connectNulls: true,
                    data: [...CcrAvgarrayspc],
                    showInLegend: IsCcrAvgSandPAvailabel
                }
            ],
            xAxis: {
                categories: [...dateArray]
            },
            yAxis: { title: { text: '' }, labels: { format: '{value: .2f}'} }
        };
        //     this.lineChartOptionsArrayccr = this.lineChartOptionsArrayccr.slice(0, 0);
        this.lineChartOptionsArrayccr = [...this.lineChartOptionsArrayccr, lineChartOptions];
    }

    private setLineChartOptionsysm(trendOverTime: CountryRiskPremia[], countrycredit: CountryCreditRating[]): void {
        // Any type is used since highchart's data property in a series accepts many data types
        let countryriskarray: any[] = [];
        let YieldSpreadeur: any[] = [];
        let YieldSpreadmsci: any[] = [];
        let YieldSpreadspc: any[] = [];

        let dateArray: string[] = [];
        let [IsCountryRiskAvailabel, IsYieldSpreadEurAvailabel, IsYieldSpreadMsciAvailabel, IsYieldSpreadSandPAvailabel] = [false, false, false, false];

        countrycredit = this.removeDuplicates(countrycredit);
        countrycredit.forEach((request) => {
            if (this.Creditratingline !== false && (request.YieldSpread !== null && request.YieldSpread !== undefined)) {
                countryriskarray = [...countryriskarray,
                {
                    y: request.YieldSpread * 100
                }
                ];
            }
        });

        trendOverTime.forEach((data) => {
            if (data.DataAsof !== undefined) {
                const date = new Date(data.DataAsof);
                if (data.Type === 'EuromoneyRegion') {
                    dateArray = [...dateArray, moment(date).format('MM/DD/YYYY')];
                }
            }
            if (data.Type === 'EuromoneyRegion' && this.euroregionline !== false && data.YieldSpreadMedian !== null) {
                YieldSpreadeur = [...YieldSpreadeur,
                {
                    y: data.YieldSpreadMedian
                }
                ];
            }
            if (data.Type === 'MsciClassification' && this.msciline !== false && data.YieldSpreadMedian !== null) {
                YieldSpreadmsci = [...YieldSpreadmsci,
                {
                    y: data.YieldSpreadMedian
                }
                ];
            }

            if (data.Type === 'SpCreditRating' && this.sprline !== false && data.YieldSpreadMedian !== null) {
                YieldSpreadspc = [...YieldSpreadspc,
                {
                    y: data.YieldSpreadMedian
                }
                ];
            }

        });
        this.YieldSpread = countryriskarray;
        this.YieldSpreadeur = YieldSpreadeur;
        this.YieldSpreadmsci = YieldSpreadmsci;
        this.YieldSpreadspc = YieldSpreadspc;

        IsCountryRiskAvailabel = countryriskarray.length > 0;
        IsYieldSpreadEurAvailabel = YieldSpreadeur.length > 0;
        IsYieldSpreadMsciAvailabel = YieldSpreadmsci.length > 0;
        IsYieldSpreadSandPAvailabel = YieldSpreadspc.length > 0;

        this.IsChartDataAvailable["CYS_MODEL"] = IsCountryRiskAvailabel || IsYieldSpreadEurAvailabel || IsYieldSpreadMsciAvailabel || IsYieldSpreadSandPAvailabel;
        this.ModelsFilterStatus[1] = this.IsChartDataAvailable["CYS_MODEL"];

        let ChartLineNameAndColours = this.GetChartNameAndColours(this.ex1, this.ex2, this.ex3, this.ex4);
        const lineChartOptions: Highcharts.Options = {
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'nunito-sans'
                },
                className: 'trends-over-time'
            },
            exporting: {
                enabled: true
            },
            title: {
                text: 'Country Yield Spread Model (%)',
                style: {
                    color: 'rgb(77, 77, 79)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem'
                }
            },
            subtitle: {
                text: 'Country Risk Indications from a ' + this.estimate?.InvestorCountryName + ' Perspective',
                style: {
                    color: 'rgb(20, 72, 127)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem',
                    fontWeight: 'bold'
                }
            },
            tooltip: {
                backgroundColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 60 },
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                },
                borderWidth: 1,
                split: true,
                valueDecimals: 2
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    type: 'line',
                    name: ChartLineNameAndColours.Country.CountryChartName,
                    color: ChartLineNameAndColours.Country.Countrycolour,
                    connectNulls: true,
                    data: [...countryriskarray],
                    showInLegend: IsCountryRiskAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.Euromoney.Euromoneychartname,
                    color: ChartLineNameAndColours.Euromoney.Euromoneycolour,
                    connectNulls: true,
                    data: [...YieldSpreadeur],
                    showInLegend: IsYieldSpreadEurAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.MSCI.MSCIchartname,
                    color: ChartLineNameAndColours.MSCI.MSCIcolour,
                    connectNulls: true,
                    data: [...YieldSpreadmsci],
                    showInLegend: IsYieldSpreadMsciAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.CreditRating.Creditratingchartname,
                    color: ChartLineNameAndColours.CreditRating.Creditratingcolour,
                    connectNulls: true,
                    data: [...YieldSpreadspc],
                    showInLegend: IsYieldSpreadSandPAvailabel
                }
            ],
            xAxis: {
                categories: [...dateArray]
            },
            yAxis: { title: { text: '' }, labels: { format: '{value: .2f}'} }
        };
        this.lineChartOptionsArrayyeildspread = [...this.lineChartOptionsArrayyeildspread, lineChartOptions];

    }
    private setLineChartOptionsrvm(trendOverTime: CountryRiskPremia[], countrycredit: CountryCreditRating[]): void {
        let countryriskarray: any[] = [];
        let VolatilityAvgeur: any[] = [];
        let VolatilityAvgmsci: any[] = [];
        let VolatilityAvgspc: any[] = [];

        let dateArray: string[] = [];
        let [IsCountryRiskAvailabel, IsRiskVolatilityEurAvailabel, IsRiskVolatilityMsciAvailabel, IsRiskVolatilitySandPAvailabel] = [false, false, false, false];

        countrycredit = this.removeDuplicates(countrycredit);
        countrycredit.forEach((request) => {

            if (this.Creditratingline !== false && request.RelativeVolatility !== null) {
                countryriskarray = [...countryriskarray,
                {
                    y: request.RelativeVolatility
                }
                ];
            }
        });

        trendOverTime.forEach((data) => {
            if (data.DataAsof !== undefined) {
                const date = new Date(data.DataAsof);
                if (data.Type === 'EuromoneyRegion') {
                    dateArray = [...dateArray, moment(date).format('MM/DD/YYYY')];
                }
            }

            if (data.Type === 'EuromoneyRegion' && this.euroregionline !== false && data.VolatilityMedian !== null) {
                VolatilityAvgeur = [...VolatilityAvgeur,
                {
                    y: data.VolatilityMedian
                }
                ];
            }
            if (data.Type === 'MsciClassification' && this.msciline !== false && data.VolatilityMedian !== null) {
                VolatilityAvgmsci = [...VolatilityAvgmsci,
                {
                    y: data.VolatilityMedian
                }
                ];
            }

            if (data.Type === 'SpCreditRating' && this.sprline !== false && data.VolatilityMedian !== null) {
                VolatilityAvgspc = [...VolatilityAvgspc,
                {
                    y: data.VolatilityMedian
                }
                ];
            }

        });
        this.volatality = countryriskarray;
        this.VolatilityAvgeur = VolatilityAvgeur;
        this.VolatilityAvgmsci = VolatilityAvgmsci;
        this.VolatilityAvgspc = VolatilityAvgspc;

        IsCountryRiskAvailabel = countryriskarray.length > 0;
        IsRiskVolatilityEurAvailabel = VolatilityAvgeur.length > 0;
        IsRiskVolatilityMsciAvailabel = VolatilityAvgmsci.length > 0;
        IsRiskVolatilitySandPAvailabel = VolatilityAvgspc.length > 0;

        this.IsChartDataAvailable["RV_MODEL"] = IsCountryRiskAvailabel || IsRiskVolatilityEurAvailabel || IsRiskVolatilityMsciAvailabel || IsRiskVolatilitySandPAvailabel;
        this.ModelsFilterStatus[2] = this.IsChartDataAvailable["RV_MODEL"];

        let ChartLineNameAndColours = this.GetChartNameAndColours(this.ex1, this.ex2, this.ex3, this.ex4);
        const lineChartOptions: Highcharts.Options = {
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'nunito-sans'
                },
                className: 'trends-over-time'
            },
            exporting: {
                enabled: true
            },
            title: {
                text: 'Relative Volatility Model',
                style: {
                    color: 'rgb(77, 77, 79)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem'
                }
            },
            subtitle: {
                text: 'Country Risk Indications from a ' + this.estimate?.InvestorCountryName + ' Perspective',
                style: {
                    color: 'rgb(20, 72, 127)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem',
                    fontWeight: 'bold'
                }
            },
            tooltip: {
                backgroundColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 60 },
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                },
                borderWidth: 1,
                split: true,
                valueDecimals: 2
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    type: 'line',
                    name: ChartLineNameAndColours.Country.CountryChartName,
                    color: ChartLineNameAndColours.Country.Countrycolour,
                    connectNulls: true,
                    data: [...countryriskarray],
                    showInLegend: IsCountryRiskAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.Euromoney.Euromoneychartname,
                    color: ChartLineNameAndColours.Euromoney.Euromoneycolour,
                    connectNulls: true,
                    data: [...VolatilityAvgeur],
                    showInLegend: IsRiskVolatilityEurAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.MSCI.MSCIchartname,
                    color: ChartLineNameAndColours.MSCI.MSCIcolour,
                    connectNulls: true,
                    data: [...VolatilityAvgmsci],
                    showInLegend: IsRiskVolatilityMsciAvailabel
                },
                {
                    type: 'line',
                    name: ChartLineNameAndColours.CreditRating.Creditratingchartname,
                    color: ChartLineNameAndColours.CreditRating.Creditratingcolour,
                    connectNulls: true,
                    data: [...VolatilityAvgspc],
                    showInLegend: IsRiskVolatilitySandPAvailabel
                }
            ],
            xAxis: {
                categories: [...dateArray]
            },
            yAxis: { title: { text: '' }, labels: { format: '{value: .2f}'} }
        };
        this.lineChartOptionsArrayvoltality = [...this.lineChartOptionsArrayvoltality, lineChartOptions];
        
    }

    private GetChartNameAndColours(countryRegion :string, euroMoneyRegion: string, msciRegion:string, creditRatingRegion: string) {
        let CountryChartName, MSCIchartname, Euromoneychartname, Creditratingchartname;
        let Countrycolour, MSCIcolour, Euromoneycolour, Creditratingcolour;

        if(this.ex1 === null) {
            this.CountryFilterStatus = false;
            CountryChartName = '';
            Countrycolour = 'rgb(255,255,255)';
        } else {
            this.EuromoneyFilterStatus = true;
            CountryChartName = 'Country ( ' + this.ex1 + ' )';
            Countrycolour = 'rgb(20,72,127)';
        }

        if(this.ex2 === null) {
            this.EuromoneyFilterStatus = false;
            Euromoneychartname = '';
            Euromoneycolour = 'rgb(255,255,255)'
        } else {
            this.EuromoneyFilterStatus = true;
            Euromoneychartname = 'Euromoney Region ( ' + this.ex2 + ' )';
            Euromoneycolour = 'rgb(76,159,200)';
        }

        if (this.ex3 === null) {
            this.MSCIFilterStatus = false;
            MSCIchartname = '';
            MSCIcolour = 'rgb(255,255,255)';
        } else {
            this.MSCIFilterStatus = true;
            MSCIchartname ='MSCI ( ' + this.ex3 + ' )';
            MSCIcolour = 'rgb(103,210,223)';
        }

        if(this.ex4 === null) {
            this.CreditRatingFilterStatus = false;
            Creditratingchartname = '';
            Creditratingcolour = 'rgb(255,255,255)';
        } else {
            this.CreditRatingFilterStatus = true;
            Creditratingchartname = 'Credit Rating ( ' + this.ex4 + ' )';
            Creditratingcolour = 'rgb(208,0,112)';
        }

        return {Country: {CountryChartName, Countrycolour}, Euromoney: {Euromoneychartname, Euromoneycolour},
                MSCI: {MSCIchartname, MSCIcolour}, CreditRating: {Creditratingchartname, Creditratingcolour}}
    }

    private getHighCharts(): any {
        const svgArr: string[] = [];
        let top = 0;
        let width = 0;
        // Reference to Highcharts forcing it to an any to utlize export utilities.
        const HC = Highcharts as any;

        HC.charts.forEach((chart: any) => {
            if (chart &&
                chart.options &&
                chart.options.chart) {

                if (chart.options.chart.className === 'trends-over-time') {

                    let svg: string = chart.getSVG();
                    const svgMatchWidth = svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/);
                    const svgMatchHeight = svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/);

                    if (svgMatchWidth && svgMatchHeight) {
                        const svgWidth = +svgMatchWidth[1];
                        const svgHeight = +svgMatchHeight[1];

                        svg = svg.replace(
                            '<svg',
                            '<g transform="translate(0,' + top + ')" '
                        );
                        svg = svg.replace('</svg>', '</g>');

                        top += svgHeight;
                        width = Math.max(width, svgWidth);

                        svgArr.push(svg);
                    }
                }
            }
        });

        return (`<svg height="${top}" width="${width}" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    ${svgArr.join('')}
                </svg>`
        );
    }

    private exportCharts(): void {
        const HC = Highcharts as any;
        let options = {
            type: 'application/pdf'
        } as Highcharts.ExportingOptions;

        options = Highcharts.merge(Highcharts.getOptions().exporting, options);

        // TODO continue researching a way to utilize offline exporting and see if multiple chart exports is capable of this.

        HC.post(options.url, {
            filename: `Trends Over Time `,
            type: options.type,
            width: options.width,
            svg: this.getHighCharts()
        });
    }
    public ngAfterViewInit(): void {
        this.trendsOverTimePDFExportService.setPdf(this.trendsovertimeriskpdf);
        this.updateFilterResult();
        setTimeout(() => { this.resizeofGraph(); }, 1000);
    }

    public ngOnInit(): void {
        this.subscription = this.userdata.Subscriptions[0].Type;
        if (this.trendsOverTimeData.length) {
            this.updateFilterResult();
            this.displayContent = true;
        }

        this.estimateSummarySelector.onceDefined((estimateData) => {
            this.estimate = estimateData;
        });
    }

    public compositestoggle(event: { target: HTMLInputElement }): void {

        if (event.target.id === '0') {
            this.Creditratingline = event.target.checked;
        }
        if (event.target.id === '1') {
            this.euroregionline = event.target.checked;
        }
        if (event.target.id === '2') {
            this.msciline = event.target.checked;
        }
        if (event.target.id === '3') {
            this.sprline = event.target.checked;
        }
        this.updateFilterResult();
    }

    public toggleDisplay(): void {
        this.displayGraph = !this.displayGraph;
    }

    public updateIndustryFilterResult(event: { target: HTMLInputElement }): void {
        if (event.target.id === '0') {
            this.creditrating = event.target.checked;
        } else if (event.target.id === '1') {
            this.yeild = event.target.checked;
        } else if (event.target.id === '2') {
            this.volatility = event.target.checked;
        }

        this.updateFilterResult();
    }

    public updateFilterResult(): void {
        this.setTableData();
        this.resizeofGraph();
    }

    public exportToPdf(): void {
        if (this.displayGraph) {
            this.exportCharts();
        } else {
            this.pdficonhide = false;
            this.trendsOverTimePDFExportService.savePdf();
            setTimeout(() => { this.pdficonhide = true; }, 2000);
        }
    }

    public exportToExcel(component: ExcelExportComponent): void {
        this.excelFileName = `country-risk-trends.xlsx`;
        this.trendsOverTimeExcelExportService.save(component, this.tableData, this.countryriskarray, this.CcrAvgarrayeur, this.CcrAvgarraymsci, this.CcrAvgarrayspc
            , this.dateArray, this.YieldSpreadeur, this.YieldSpreadmsci, this.YieldSpreadspc, this.VolatilityAvgeur, this.VolatilityAvgmsci, this.VolatilityAvgspc, this.YieldSpread, this.volatality);
    }
    public resizeofGraph(): void {
        setTimeout(() => { this.setGraphData(); }, 1000);
    }
    public removeDuplicates(arr: CountryCreditRating[]): CountryCreditRating[] {
        const temp: CountryCreditRating[] = [];
        arr.forEach((x) => {
            if (temp.filter((y) => y.DataAsOf === x.DataAsOf).length <= 0) {
                temp.push(x);
            }
        });
        return temp;
    }

    private showChartFullSize(chartType: string) {
        var chartObj, fullSizeChart;
        if (chartType == "CCR_MODEL") {
            chartObj = this.lineChartOptionsArrayccr[0];
        } else if (chartType == "CYS_MODEL") {
            chartObj = this.lineChartOptionsArrayyeildspread[0];
        } else if (chartType == "RV_MODEL") {
            chartObj = this.lineChartOptionsArrayvoltality[0]; 
        }

        if(chartObj && chartObj !== undefined) {
            fullSizeChart = new Chart({
                chart: chartObj.chart,
                exporting: chartObj.exporting,
                title: chartObj.title,
                subtitle: chartObj.subtitle,
                tooltip: chartObj.tooltip,
                credits: chartObj.credits,
                series: chartObj.series,
                xAxis: chartObj.xAxis,
                yAxis: chartObj.yAxis,
                responsive: {
                    rules: [
                        {
                            condition: {
                                minWidth: 1697
                            }, chartOptions: {
                                chart: {
                                    height: 500
                                }
                            },
                        },
                        {
                            condition: {
                                minWidth: 1273, maxWidth: 1696
                            }, chartOptions: {
                                chart: {
                                    height: 400
                                }
                            },
                        },
                        {
                            condition: {
                                minWidth: 0, maxWidth: 1272
                            }, chartOptions: {
                                chart: {
                                    height: 250
                                }
                            },
                        },
                    ]
                }
            });
            this.fullSizeChart = fullSizeChart;
        }
        this.isFullScreenDisplay = !this.isFullScreenDisplay;
    }

    private exitFullScreenChart() {
        this.isFullScreenDisplay = false;
    }
}
